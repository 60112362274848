export default [
  {
    header: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'Home',
        route: 'home',
        icon: 'HomeIcon',
      },
      {
        title: 'Pracownicy',
        route: 'Eployees',
        icon: 'FileIcon',
        children: [
          {
            title: 'Dodaj pracownika',
            route: 'EmployeeView',
          },

        ],
      },
    ],
  },
]
